import { defineStore } from 'pinia'

export const useCategoryStore = defineStore('category', () => {
  const categories = ref({
    'zene-film-kultura': {
      id: 2,
      name: 'Csupa Magyar',
      slug: 'csupa-magyar',
      icon: 'ikon_csupa_sotet.png',
      active: true
    },
    sport: {
      id: 5,
      name: 'Piramisok Világa',
      slug: 'piramisok-vilaga',
      icon: 'ikon_piramisok_sotet.png',
      active: true
    },
    elet: {
      id: 10,
      name: 'Rejtélyes Föld',
      slug: 'rejtelyes-fold',
      icon: 'ikon_rejtelyes_sotet.png',
      active: true
    },
    'gasztro-utazas': {
      id: 3,
      name: 'Nagy Kérdések',
      slug: 'nagy-kerdesek',
      icon: 'ikon_nagy_sotet.png',
      active: true
    },
    szines: {
      id: 4,
      name: 'Kvízek',
      slug: 'kvizek',
      icon: 'ikon_kviz_sotet.png',
      active: true
    },
    'napi-csaj': {
      id: 11,
      name: 'Napi csaj',
      slug: 'napi-csaj',
      icon: 'ikon_napi_sotet.png',
      active: false
    },
    csalamade: {
      id: 12,
      name: 'Csalamádé',
      slug: 'csalamade',
      icon: 'csalamade_vilagos.png',
      active: true
    }
  })

  const activeCategoryId = ref<number|null>(null)

  const getCategoryByName = (slug: string) => {
    return categories.value[
      Object.keys(categories.value).find(
        category => categories.value[category].slug === slug
      )
    ]
  }

  const getCategoryById = (id: number) => {
    return categories.value[
      Object.keys(categories.value).find(
        category => categories.value[category].id === id
      )
    ]
  }

  const setActiveCategoryId = (id: number) => {
    activeCategoryId.value = id
  }

  const getMainMenuCategories = Object.values(categories.value).filter(category => category.active === true)

  const getActiveCategory = () => {
    return activeCategoryId.value
      ? getCategoryById(activeCategoryId.value)
      : null
  }

  return { getCategoryById, getCategoryByName, categories, activeCategoryId, setActiveCategoryId, getActiveCategory, getMainMenuCategories }
})
